export const ExternalUrl = {
  web_app: {
    url: 'https://stopcovid-voe.anamai.moph.go.th/web-front',
    target: '_blank',
  },
  web_app_register: {
    url: 'https://stopcovid-voe.anamai.moph.go.th/web-front/auth/register',
    target: '_blank',
  },
  web_admin: {
    url: 'https://stopcovid-voe.anamai.moph.go.th/web-admin',
    target: '_blank',
  },
  ios_app: {
    url: 'https://apps.apple.com/th/app/thaistopcovid-plus/id1587833901',
    target: '_blank',
  },
  android_app: {
    url: 'https://play.google.com/store/apps/details?id=moph.go.th.thai_stop_covid_plus',
    target: '_blank',
  },
  mobile_manual: {
    url: '/assets/files/mobile-manual.pdf',
    target: '_blank',
  },
  web_manual: {
    url: '/assets/files/web-application-manual.pdf',
    target: '_blank',
  },
  web_admin_manual: {
    url: '/assets/files/webadmin-manual.pdf',
    target: '_blank',
  },
};
