import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExternalUrl } from './share/global';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  externalUrl: any = ExternalUrl;

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'th']);
    translate.setDefaultLang('th');
  }
}
