<div class="header-wrapper" id="header">
  <div class="container">
    <div class="header">
      <div class="logo">
        <img src="/assets/images/logo.png" alt="app-logo">
      </div>
      <div class="navbar-toggler-wrapper">
        <button class="navbar-toggler-btn" (click)="isMenuCollapsed = !isMenuCollapsed" [ngClass]="{ open: !isMenuCollapsed }">
          <div class="bar bar-animate-1"></div>
          <div class="bar bar-animate-2"></div>
          <div class="bar bar-animate-3"></div>
        </button>
      </div>
      <div class="navbar">
        <ul class="navbar-list">
          <li><a (click)="scrollTo('download')"> {{ 'DOWNLOAD' | translate }} </a></li>
          <li><a (click)="scrollTo('about')"> {{ 'BACKGROUND_AND_SERVICE' | translate }} </a></li>
          <li><a (click)="scrollTo('manual')"> {{ 'USER_MANUAL' | translate }} </a></li>
          <li><a (click)="scrollTo('contact')"> {{ 'CONTACT_US' | translate }} </a></li>
          <li>
            <div class="lang">
              <div class="lang-flag mr-10" (click)="changeLanguage('th')">
                <img src="/assets/images/thailand.png" alt="th">
              </div>
              <div class="lang-flag" (click)="changeLanguage('en')">
                <img src="/assets/images/us.png" alt="en">
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="mobile-menu" [collapse]="isMenuCollapsed" [isAnimated]="true">
  <div class="mobile-menu-inner">
    <div class="container">
      <ul class="navbar-list">
        <li><a (click)="scrollTo('download')"> {{ 'DOWNLOAD' | translate }} </a></li>
        <li><a (click)="scrollTo('about')"> {{ 'BACKGROUND_AND_SERVICE' | translate }} </a></li>
        <li><a (click)="scrollTo('manual')"> {{ 'USER_MANUAL' | translate }} </a></li>
        <li><a (click)="scrollTo('contact')"> {{ 'CONTACT_US' | translate }} </a></li>
        <li>
          <div class="lang">
            <div class="lang-flag mr-10" (click)="changeLanguage('th')">
              <img src="/assets/images/thailand.png" alt="th">
            </div>
            <div class="lang-flag" (click)="changeLanguage('en')">
              <img src="/assets/images/us.png" alt="en">
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>