import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isMenuCollapsed = true;

  @HostListener('window:scroll', ['$event']) onScrollEvent($event) {
    let position = $event.target.scrollingElement.scrollTop;
    let element = document.getElementById('header');
    if (position > 80) {
      if (!element.classList.contains('shrink')) {
        element.classList.add('shrink');
      }
    } else {
      element.classList.remove('shrink');
    }
  }

  constructor(
    private translate: TranslateService,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  changeLanguage(lang: string) {
    this.translate.use(lang);
  }

  scrollTo(anchorName: string): void {
    this.viewportScroller.scrollToAnchor(anchorName);
    this.isMenuCollapsed = true;
  }
}
