<section class="home">
  <div class="hero-bg">
    <!-- HEADER -->
    <app-header></app-header>
    <!-- /HEADER -->

    <!-- HERO BANNER -->
    <div class="anchor" id="download"></div>
    <div class="container full-height">
      <div class="hero-banner">
        <div class="hero-banner-text">
          <h1 class="hero-text">
            Thai ST<span class="tr">O</span>P COVID<span class="tg">+</span>
          </h1>
          <h4 class="hero-sub-text" [innerHtml]="'SLOGAN' | translate"></h4>
          <button class="btn btn-start btn-with-link">
            <a [href]="externalUrl.web_app.url" [target]="externalUrl.web_app.target">
              {{ "GET_START" | translate }}
              <img src="/assets/images/arrow-right.png" alt="icon" />
            </a>
          </button>

          <!-- <h3 class="csm-text hidden-mobile">{{ 'COMING_SOON' | translate }}</h3> -->
          <div class="app-store">
            <!-- <img class="cms mr-15" src="/assets/images/app_store.png" alt="app-store"> -->
            <!-- <img class="cms" src="/assets/images/google_play.png" alt="google-store"> -->
            <a [href]="externalUrl.ios_app.url" [target]="externalUrl.ios_app.target" class="mr-15">
              <img src="/assets/images/app_store.png" alt="app-store" />
            </a>
            <a [href]="externalUrl.android_app.url" [target]="externalUrl.android_app.target">
              <img src="/assets/images/google_play.png" alt="google-store" />
            </a>
          </div>
        </div>
        <div class="hero-banner-img">
          <img class="mockup" src="/assets/images/phone_mockup.png" alt="phone" />
          <!-- <h3 class="csm-text hidden-desktop mb-0 text-center mt-25">{{ 'COMING_SOON' | translate }}</h3> -->
          <div class="app-store">
            <!-- <img class="cms mr-15" src="/assets/images/app_store.png" alt="app-store"> -->
            <!-- <img class="cms" src="/assets/images/google_play.png" alt="google-store"> -->
            <a [href]="externalUrl.ios_app.url" [target]="externalUrl.ios_app.target" class="mr-15">
              <img src="/assets/images/app_store.png" alt="app-store" />
            </a>
            <a [href]="externalUrl.android_app.url" [target]="externalUrl.android_app.target">
              <img src="/assets/images/google_play.png" alt="google-store" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /HERO BANNER -->
  </div>

  <!-- ABOUT -->
  <div class="anchor" id="about"></div>
  <div class="container">
    <div class="about-wrapper">
      <h1 class="about-title" [innerHtml]="'ABOUT_TITLE' | translate"></h1>
      <div class="hr"></div>

      <div class="about-desc">
        <p [innerHtml]="'ABOUT_BODY' | translate"></p>
        <img class="quote open" src="../assets/images/double-quote-open.png" alt="quote" />
        <img class="quote close" src="../assets/images/double-quote-close.png" alt="quote" />
      </div>

      <button class="btn btn-start mx-auto btn-with-link">
        <a [href]="externalUrl.web_app.url" [target]="externalUrl.web_app.target">
          {{ "GET_START" | translate }}
          <img src="/assets/images/arrow-right.png" alt="icon" />
        </a>
      </button>
    </div>
  </div>
  <!-- /ABOUT -->

  <!-- MANUAL -->
  <div class="anchor" id="manual"></div>
  <div class="manual-wrapper">
    <div class="container">
      <h3>{{ "USER_AND_MANUAL" | translate }}</h3>
      <div class="manual-list">
        <div class="manual">
          <div class="title-wrapper">
            <div class="icon">
              <img src="../assets/images/smart-phone.png" alt="ic-phone" />
            </div>
            <h5 [innerHtml]="'MOBILE_APPLICATION_MANUAL' | translate"></h5>
          </div>
          <button class="btn btn-sm mx-auto btn-with-link">
            <a [href]="externalUrl.mobile_manual.url" [target]="externalUrl.mobile_manual.target" class="primary">
              <img src="/assets/images/download.png" alt="icon" />
              {{ "DOWNLOAD_MANUAL" | translate }}
            </a>
          </button>
        </div>
        <div class="manual">
          <div class="title-wrapper">
            <div class="icon">
              <img class="mt-5" src="../assets/images/desktop.png" alt="ic-desktop" />
            </div>
            <h5 [innerHtml]="'WEB_APPLICATION_MANUAL' | translate"></h5>
          </div>
          <button class="btn btn-sm mx-auto btn-with-link">
            <a [href]="externalUrl.web_manual.url" [target]="externalUrl.web_manual.target" class="primary">
              <img src="/assets/images/download.png" alt="icon" />
              {{ "DOWNLOAD_MANUAL" | translate }}
            </a>
          </button>
        </div>
        <!-- <div class="manual">
          <div class="title-wrapper">
            <div class="icon">
              <img class="mt-5" src="../assets/images/desktop-admin.png" alt="ic-desktop" />
            </div>
            <h5 [innerHtml]="'WEB_ADMIN_MANUAL' | translate"></h5>
          </div>
          <button class="btn btn-sm mx-auto btn-with-link">
            <a [href]="externalUrl.web_admin_manual.url" [target]="externalUrl.web_admin_manual.target" class="primary">
              <img src="/assets/images/download.png" alt="icon" />
              {{ "DOWNLOAD_MANUAL" | translate }}
            </a>
          </button>
        </div> -->
      </div>

      <!-- YOUTUBE -->
      <div class="video-manual">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/068sBQuAdAU" title="Thai STOP COVID+ - User manual" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <!-- /YOUTUBE -->
    </div>
  </div>
  <!-- /MANUAL -->

  <!-- FOOTER -->
  <div class="anchor" id="contact"></div>
  <app-footer></app-footer>
  <!-- /FOOTER -->
</section>