<div class="footer-wrapper">
  <div class="container">

    <div class="row">
      <!-- <div class="col-md-2"></div> -->
      <div class="col-md-4">
        <div class="info">
          <h3>{{ 'ANAMAI_MOPH' | translate }}</h3>
          <div class="address">
            <p>{{ 'ANAMAI_MOPH_ADDRESS' | translate }}</p>
            <p>{{ 'PHONE' | translate }}: <a class="inline-link" href="tel:025904333">02-590-4333</a></p>
            <p>{{ 'EMAIL' | translate }}: <a class="inline-link" href="mailto:stopcovid@anamai.mail.go.th">stopcovid@anamai.mail.go.th</a></p>
          </div>
          <h3 class="mt-30">{{ 'HOTLINE' | translate }}</h3>
          <div class="hotline-list">
            <button class="btn btn-sm">
              <a class="primary" href="tel:0811371633">
                <img src="/assets/images/phone.png" alt="icon">
                081-137-1633
              </a>
            </button>
            <button class="btn btn-sm">
              <a class="primary" href="tel:025904333">
                <img src="/assets/images/phone.png" alt="icon">
                02-590-4333
              </a>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="hr invert mt-30"></div>
        <div class="mobile-nav">
          <h3>{{ 'FOR_ESTABLISHMENT' | translate }}</h3>
          <ul class="menu-list">
            <li><a (click)="scrollTo('download')"> {{ 'DOWNLOAD' | translate }} </a></li>
            <li><a (click)="scrollTo('about')"> {{ 'BACKGROUND_AND_SERVICE' | translate }} </a></li>
            <li><a (click)="scrollTo('manual')"> {{ 'USER_MANUAL' | translate }} </a></li>
            <li><a [href]="externalUrl.web_app.url" [target]="externalUrl.web_app.target"> {{ 'LOGIN' | translate }} </a></li>
            <li><a [href]="externalUrl.web_app_register.url" [target]="externalUrl.web_app_register.target"> {{ 'REGISTER' | translate }} </a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-4">
        <div class="hr invert mt-30"></div>
        <div class="mobile-nav mb-0">
          <h3>{{ 'FOR_STAFF' | translate }}</h3>
          <ul class="menu-list">
            <li><a [href]="externalUrl.web_admin.url" [target]="externalUrl.web_admin.target"> {{ 'LOGIN' | translate }} </a></li>
          </ul>
        </div>
      </div>
      <!-- <div class="col-md-2"></div> -->
    </div>

    <div class="counter">
      {{ 'VISITOR_AMOUNT' | translate }}<br>
      <img src="https://counter2.stat.ovh/private/freecounterstat.php?c=b7838pn3rgjsn98j39huzauakcry32a3" border="0" title="website counter" alt="website counter">
    </div>
  </div>
</div>
<div class="copyright">
  <div class="container">
    Copyright 2021 Department of Health : Ministry of Public Health
  </div>
</div>