import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ExternalUrl } from 'src/app/share/global';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  externalUrl: any = ExternalUrl;

  constructor(
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
  }

  scrollTo(anchorName: string): void {
    this.viewportScroller.scrollToAnchor(anchorName);
  }
}
